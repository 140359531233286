<template>
  <div class="shinua">
      <Button @click="show_new_shinua=!show_new_shinua" style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="טופס קליטת שינוע" class="p-button-success" />
      <Button v-if="user_role>=99"  @click="show_manage_shinua=!show_manage_shinua" style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="ניהול טפסים" class="p-button-warning" />
      <Dialog header="צור שינוע חדש"  v-model:visible="show_new_shinua">
        <NewShinua />
      </Dialog>
      <Dialog header="שינוע מסך ניהול"  v-model:visible="show_manage_shinua">
        <ManageShinua />
      </Dialog>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import store from '../../../store'
import { computed, onMounted } from '@vue/runtime-core'
import NewShinua from '../shinua/components/NewShinua.vue'
import ManageShinua from '../shinua/components/ManageShinua.vue'
import Dialog from 'primevue/dialog';
export default {
    components:{NewShinua,Dialog,ManageShinua},
    setup(){

        const show_new_shinua = ref(false)
        const show_manage_shinua = ref(false)
        const user_role = ref(computed(()=>{
            return store.getters.role
        }))
        const userProfile=ref(computed(()=>{
            return store.getters.user
        }))

        onMounted(()=>{
            setTimeout(() => {
                console.log( userProfile.value);
            }, 1000);
        })
        return{user_role,userProfile,show_new_shinua,show_manage_shinua}
    }
}
</script>

<style>
    .shinua{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>